import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
 import { Header } from './components/headerNewHousing'
// // import { Features } from './components/features'0
// import { About } from './components/about'
// import { Services } from './components/services'
// // import { Gallery } from './components/gallery'
// // import { Testimonials } from './components/testimonials'
// import { Team } from './components/Team'
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// import Capabilities from './components/capabilities';
// import EcoHouse from './components/EcoHouse'

// import "video-react/dist/video-react.css"; // import css
// import { Player } from "video-react";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const NewHousing = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header />


      <div  style={{color: '#fff', margin: '50px ', textAlign: 'center' 
	}} className="intro-text2">

                <a
                href="/video.mp4"
                target="_blank"
                rel="noopener noreferrer"
                  
                  className='btn btn-custom btn-lg page-scroll '
                >
                 <h2 style={{color: '#fff'}}>Watch House Video</h2> 
                </a>{' '}
                </div>


      

      

{/*  
      <div className="d-flex justify-content-center">
        <Player
          playsInline
          poster="/assets/poster.png"
          src="/video.mp4"
          fluid={false}
          width={1000}
          height={500}
        ></Player>
      </div>  */}

      <Contact data={landingPageData.Contact} />

      
    </div>
  );
};

export default NewHousing;
