import React from 'react'

export const Partner = () => {
  return (
    <div id='partners' className='text-center' >
    <div className='container'>
      <div className='section-title'>
        <h2 className='text-white'>Our Partner in Spain</h2>
        <p>
                Elivere
.
        </p>
      </div>
      <div className='row'>

        <a href='https://elivere.com/' target="_blank" rel='noreferrer' >
        <img src='img/partners/elivere.png' alt='elivere' />

        </a>

        
        
       </div>
    </div>
  </div>
  )
}
