import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
// import { Features } from './components/features'0
import { About } from './components/about'
import { Services } from './components/services'
// import { Gallery } from './components/gallery'
// import { Testimonials } from './components/testimonials'
import { Team } from './components/Team'
import { Contact } from './components/contact'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import Capabilities from './components/capabilities';
import EcoHouse from './components/EcoHouse'
import { Partner } from './components/Partner'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      {/* <Features data={landingPageData.Features} /> */}
      <EcoHouse data={landingPageData.feactures} />
      <Services data={landingPageData.Services} />
     
      {/* <Gallery /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      <Team data={landingPageData.Team} /> 
      <Partner />
      <Capabilities data={landingPageData.Capabilities} />
      <Contact data={landingPageData.Contact} />
    </div>
  )
}

export default Home
