export const Team = (props) => {

  console.log(props)
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
            We team is integrated with the best in your profession.
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-lg-3 col-md-3 col-sm-6 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.img} alt='...' className='team-img' />
                  <div className='caption'>
                    <h3>{d.name}</h3>
                    <h5>{d.job}</h5>
                    <p>{d.profession}</p>
                    {/* <p>{d.cv}</p> */}

                    {/* {d.cv.map((h, i) => (
                      <p key={`${d.text}-${i}`}>{h.text}</p>
                    ))} */}

                    <ModalTeam

                      name={d.name}
                      id={i}
                      cv={d.cv}
                    />
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

export const ModalTeam = (props) => {
  let titleid = `#exampleModalLong${props.id}`
  let title = `exampleModalLong${props.id}`
  let exampleModalLongTitle = `exampleModalLongTitle${props.id}`
  // let idteam = props.id;
  // let cv = props.cv

  console.log(`datos props: ${JSON.stringify(props.cv)}`)


  return (
    <div>
      <button type="button" className="btn btn-primary" data-toggle="modal" data-target={titleid}>
        More Information
      </button>
      {/* Modal */}
      <div className="modal fade" id={title} tabIndex={-1} role="dialog" aria-labelledby={exampleModalLongTitle} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={exampleModalLongTitle}>{props.name} Details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
             
                <h1>{props.name}</h1>
                
              <ul>

                {props.cv
                  ? props.cv.map((d, i) => (

                    <li>
                      <div key={`${d.text}-${i}`} >


                        <p>{d.text}</p>
                      </div>
                    </li>

                  ))
                  : 'loading'}

              </ul>
         
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
