export const Capabilities = (props) =>{
    return( 
        
        <div id='capabilities'>
            <div className="container">
                <div className="row ">

                <div className="about-text">
                            <h2 className="text-center">Capabilities</h2>
                        </div>
                    <div className="col-xs-12 col-md-12 col-lg-12">

                        <div className="list-style">
                            <div className="col-lg-4 col-md-4">
                                <h3>Internal Capabilities</h3>
                                <ul>
                                    <li><p>Advisory/Evaluation/Assessments</p>   </li> 
                                    <li><p>Engineering</p>   </li> 
                                    <li><p>Systems Integration</p>   </li> 
                                    <li><p>Field Installation</p>   </li> 
                                    <li><p>Project Management</p>   </li>
                                    <li><p>Equipment Engineering and Procurement</p>   </li>  
                                    <li><p>Commissioning and Launch</p>   </li>   
                                </ul>
                            </div>
                        </div>

                        <div className="list-style">
                            <div className="col-lg-4 col-md-4">
                                <h3>External Resources</h3>
                                <ul>
                                    <li><p>Worldwide Network</p>   </li> 
                                    <li><p>Manufacturing</p>   </li> 
                                    <li><p>Competitive Pricing</p>   </li> 
                                    <li><p>Long-Term Support</p>   </li> 
                                       
                                </ul>
                            </div>
                        </div>

                        <div className="list-style">
                            <div className="col-lg-4 col-md-4">
                                <h3>Turnkey Custom Solutions</h3>
                                <ul>
                                    <li><p>Address Alternative/Renewable</p>   </li> 
                                    <li><p>Energy Needs of the Client</p>   </li> 
                                    <li><p>Provide Customized Solutions</p>   </li> 
                                    <li><p>Lower Prices</p>   </li> 
                                    <li><p>Flexibility in Design and</p>   </li>
                                    <li><p>Implementation</p>   </li>  
                                    <li><p>Optimal Mix of Energy Resources</p>   </li>   
                                    <li><p>Potential to Create Local</p>   </li> 
                                    <li><p>Employment</p>   </li> 
                                </ul>
                            </div>
                        </div>

                        

                        {/* <h4>{props.data ? 'hay datos' + props.data.header : 'loading...' }</h4> */}






                            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Capabilities;