const EcoHouse = (props) => {
  return (
    <div id="ecohouse" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>ECO HOUSE</h2>

          <h1>Modern, Comfortable, Technological & Economical.</h1>
        </div>
        {/* <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div> */}

        <div className="row">
          <div className="col-xs-12">
            {" "}
            <img
              src="img/CALGETCH.jpeg"
              className="img-responsive "
              alt=""
            />{" "}
          </div>

          <div className="mt-11 ">
            <a
              href="https://calgtech.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" class="btn btn-success mt-5" style={{margin: '10px'}}>
                More Info visit Calgtech{" "}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcoHouse;
