export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro2'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text2 flex-1'>
                <h1>
                  {props.data ? props.data.title : 'New House Technology'}
                  <span></span>
                </h1>
                {/* <p>{props.data ? props.data.paragraph : 'Loading'}</p> */}
                <a
                href="/presentation.pdf"
                target="_blank"
                rel="noopener noreferrer"
                  
                  className='btn btn-custom btn-lg page-scroll'
                >
                 <h2>Load Presentation</h2> 
                </a>{' '}

                {/* <div className="">

                <a
                href="/video.mp4"
                target="_blank"
                rel="noopener noreferrer"
                  
                  className='btn btn-custom btn-lg page-scroll '
                >
                 <h2>Watch House Video</h2> 
                </a>{' '}
                </div> */}

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
