import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './Home'
import NewHousing from './NewHousing'

// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// })

const App = () => {

  // const [landingPageData, setLandingPageData] = useState({})
  // useEffect(() => {
  //   setLandingPageData(JsonData)
  // }, [])

  return (
    <Router>
     
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path='/newhousing' element={< NewHousing />} />
          {/* <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/recovery-password" element={<RecoveryPassword/>}/>
          <Route path="*" element={<NotFound/>}/> */}
        </Routes>
      
    </Router>
  )
}

export default App
